@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');
@import url('./components/customPhoneInput.css');
@import url('./components/markdown.css');

body {
    width: 100%;

    @apply font-body;
    @apply text-primary-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-heading;
    @apply font-bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    @apply underline-offset-[0.25em];
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

input:focus {
    box-shadow: none !important;
}

input[type='search']::-webkit-search-cancel-button {
    display: none;
}

input[type='password']::-ms-reveal {
    @apply hidden;
}

@layer utilities {
    .text-shadow {
        text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    }
}

select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}
